import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: {
          personalData: 'Personal Data',
          name: 'Full Name',
          dateBirth: 'Date of Birth',
          nationality: 'Nationality',
          socialName: 'Social Name',
          personalEmail: 'Personal E-mail',
          homeState: 'Home State',
          naturalness: 'Naturalness',
          sex: 'Sex',
          male: 'Male',
          female: 'Female',
          colorRace: 'Color Race',
          educationDegree: 'Education Degree',
          maritalStatus: 'Marital Status',
          retired: 'Retired?',
          yes: 'Yes',
          no: 'No',
          retirementForTimeService: 'Retirement for time of service',
          retirementByAge: 'Retirement by age',
          specialRetirement: 'Special Retirement',
          retirementDate: 'Retirement Date',
          typeRetirement: 'Type of Retirement',
          requiredFields: 'Required Fields',
          advanceButton: 'Advance',
          backButton: 'Back',
          startButton: 'Start',
          saveButton: 'Save',
          finishButton: 'Finish',
          homeAddress: 'Home Address',
          zipCode: 'Zip Code',
          type: 'Type',
          street: 'Street',
          number: 'Number',
          complement: 'Address Line 2',
          typeNeighborhood: 'Type of Neighborhood',
          neighborhood: 'Neighborhood',
          country: 'Country',
          state: 'State',
          city: 'City',
          waitMessage: 'Please wait, we are processing your information.',
          education: 'Education',
          includeEducation: 'Include your education',
          course: 'Course',
          progress: 'Progress',
          add: 'Add',
          concluded: 'Concluded',
          inProgress: 'In progress',
          locked: 'Locked',
          interrupted: 'Interrupted',
          confirmDeleteMessage:
            'Do you really want to remove the registration?',
          typeCertification: 'Type of Certification',
          certificationIssueDate: 'Certification issue date',
          certifyingEntity: 'Certifying Entity',
          expirationDate: 'Expiration Date',
          editButton: 'Edit',
          deleteButton: 'Delete',
          loading: 'Loading...',
          personWithDisability: 'Person with disability',
          disabilityWithMedicalCertificate:
            'Do you have any type of disability with a medical certificate?',
          typeDisability: 'Type of disability',
          pwd: 'PWD',
          parentingDependents: 'Dependents / Parenting',
          dependents: 'Dependents',
          degreeKinship: 'Degree of Kinship',
          languages: 'Languages',
          checkInformation: 'Check your information: ',
          summary: 'Summary',
          classOrgan: 'Class Organ',
          numberClassOrgan: 'Registration number in class organ',
          registryState: 'State of registry',
          emissionDate: 'Emission date',
          haveRegistryClassOrgan: 'Do you have registry in an organ class?',
          operator: 'Operator',
          modality: 'Modality',
          quantityDay: 'Quantity/Day',
          totalDay: 'Total/Day',
          spouseData: 'Spouse/Partner Data',
          dependentIncomeTax: 'Dependent Income Tax',
          pension: 'Pension',
          dependentHealthPlan: 'Dependent on health plan',
          dependentDentalCare: 'Dependent on dental care',
          courseName: 'Course Name',
          institution: 'Institution',
          institutionName: 'Institution Name',
          courseProgress: 'Course Progress',
          startDate: 'Start Date',
          endDate: 'End Date',
          start: 'Start',
          transportationVouchers: 'Transportation Vouchers',
          addDocument: 'Add Document',
          remove: 'Remove',
          noDocumentRegistered: 'No documents registered!',
          documents: 'Documents',
          errorMessage: 'Ops! Something went wrong.',
          contactResponsableMessage:
            'Please contact the person responsible for more information.',
          typeVoucher: 'Type of voucher:',
          dependentsWithBenefit: 'Dependents with benefit membership',
          dependentStage: 'Go to dependents stage',
          dataSavedMessage: 'Data Saved Successfully',
          processNotCompletedMessage:
            'However, the process has not yet been completed, you must return and confirm the completion of the process.',
          return: 'Return',
          maxSizeAllowed: 'Maximum size allowed',
          emptyFile: 'The file is empty.',
          initialInformation: 'Initial Information',
          address: 'Address Line 1',
          notInformed: 'Not informed',
          unfilledDocuments: 'Unfilled documents',
          documentsFilled: 'All documents have been completed',
          birthplace: 'Birthplace',
          organ: 'Entity',
          describeOtherCertification:
            'Other (describe the other certification)',
          certificationDateOfIssue: 'Certification date of issue',
          certificationExpirationDate: 'Certification expiration date',
          option: 'Option',
          certifications: 'Certifications',
          haveCertification: 'Do you have certification?',
          publicAgent: 'Public Agent',
          whatRelationship: 'What relationship',
          inss: 'INSS Collected in another company',
          job: 'Job',
          workAnotherCompany: 'Works at another company',
          company: 'Company',
          role: 'Role',
          whatJob: 'What Job?',
          groupCompany: 'Which group company',
          familyAtCompany: 'Family in the company',
          family: 'Family',
          participationCompanies: 'Do you own any percentage of a company?',
          companyName: 'Company name',
          foundationDate: 'Foundation date',
          participationPercentage: 'Percent of participation',
          Currency: 'Currency',
          monthlyPerformance: 'Monthly performance',
          supplier: 'Supplier',
          wichSupplierAndRelationship: 'Which supplier and which relationship',
          supplierConnection: 'Connection with supplier',
          origin: 'Origin',
          assetsRealState: 'Assets / Real Estate',
          originIncludedFromSpouse:
            'Origin (including from the spouse, e.g. rentals, etc.)',
          typePropety: 'Type of property',
          financed: 'Financed',
          creditor: 'Creditor',
          debitBalance: 'Debit balance',
          assetsRealStateAndOthers: 'Assets / Properties / Other income',
          brand: 'Brand',
          model: 'Model',
          manufacturing: 'Manufacturing',
          acquisitionValue: 'Acquisition value',
          manufactureYearModel: 'Manufacture (year / model)',
          vehicles: 'Vehicles',
          vehicle: 'Vehicle',
          privateTransport: 'Private transport',
          wichVehicle: 'Which vehicle?',
          workVehicle: 'Go to work with a vehicle',
          select: 'Select...',
          confirmMessageParticipationCompanies:
            'Do you own more than 10% of a public trade or private company?',
          confirmMessageFamily:
            'Do you have a family member or Person in your immediate household who is a collaborator or third party related to @groupCompanyName?',
          confirmMessageSupplier:
            'Do you or any family member or immediate dependent have a connection with a supplier that serves @groupCompanyName?',
          confirmMessagePublicAgent:
            'Are you or a family member or Person in your immediate household who is or was a Public Agent or PEP in the last 5 years, even if licensed, on leave or retired?',
          confirmMessagePrivateTransport: 'Use private vehicle to work?',
          confirmMessagePaidActivity:
            'Do you carry out any other paid activity, even without employment or on a sporadic basis?',
          confirmMessageJob: 'Do you work at another company?',
          confirmMessageCertifications: 'Do you have certification?',
          advanceAndSaveButton: 'Advance and Save',
          cpfOrSsn: 'SSN',
          cancel: 'Cancel',
          unableToAddNewRecord: 'Unable to add a new record',
          addNewRecordToFoward: 'Add a record to move forward',
          requiredStep: 'Required step, insert one or more records.',
          unableToAdvance: 'Unable to advance, please try again later',
          leaveWithoutSaving:
            'Changed fields will not be saved. Do you want to exit without saving?',
          isRequired: 'is required.',
          institutionNameRequired: 'Institution name is required.',
          courseNameRequired: 'Course name is required.',
          endDateRequired: 'End date is required.',
          nameRequired: 'Name is required',
          documentRequired: 'Document is required',
          disabilityRequired: 'Type of disability is required.',
          pleaseFillTheField: 'Please fill in the field',
          docTypeImage: 'Image',
          docTypeDoc: 'Docu (pdf, word)',
          docTypeImageDoc: 'Image / Doc (pdf, word)',
          docZip: 'zip',
          docAll: 'All',
          noCodeEntered: 'No code was entered.',
          codeValidated: 'Code validated successfully.',
          smsSent: 'SMS sent successfully.',
          completed: 'Completed',
          inProgress: 'In Progress',
          locked: 'Locked',
          interrupted: 'Interrupted',
          selectAll: 'Select all',
          typeActivity: 'Type of activity',
          frequency: 'Frequency',
          period: 'Period',
          invalidName: 'Invalid name.',
          paidActivity: 'Paid Activity',
          theField: 'The field',
          relationship: 'Relationship',
          supplierAndRelationship: 'Supplier and relationship',
          actualLocationOfWork: 'Actual location of work',
          fullNameOfPersonWithRelationship: 'Full name of the related person',
          placeOfWork: 'Place of work',
          WorkInDifferentCityFromWhereYouLive: 'Do you work in a different city from where you live?',
          enterTheCityWhereYouWork: 'If yes, enter the city where you work.',
          anotherLanguage: 'Another language',
          levelOfAnotherLanguage: 'Level of the another language',
          english: 'English',
          spanish: 'Spanish',
          cnpjOrEin: 'EIN',
          invalidCnpjOrEin: 'Invalid EIN.',
          atLeastOneParentMustBeAdded: 'At least one parent must be added.',
          cepOrZipCodeRequired: 'Zip code is required.',
          cepOrZipCodeInvalid: 'Invalid zip code.',
          portuguese: 'Portuguese',
          married: 'Married',
          toAccessTheFormSMSMessage: "To access the form we'll send a code via SMS to the phone with the ending",
          theCodeIsValidForTenMinutes: 'The code is valid for 10 minutes.',
          ifItsNearYourCellPhone: "If you have your phone nearby, click on the button below receive the code.",
          code: 'Code',
          resend: 'Resend',
          sendSMS: 'Send SMS',
        },
      },
      br: {
        translations: {
          personalData: 'Dados Pessoais',
          name: 'Nome Completo',
          dateBirth: 'Data de Nascimento',
          nationality: 'Nacionalidade',
          socialName: 'Nome Social',
          personalEmail: 'E-mail Pessoal',
          homeState: 'Estado Natal',
          naturalness: 'Naturalidade',
          sex: 'Sexo',
          male: 'Masculino',
          female: 'Feminino',
          colorRace: 'Cor Raça',
          educationDegree: 'Grau de Instrução',
          maritalStatus: 'Estado Civil',
          retired: 'Aposentado (a)?',
          yes: 'Sim',
          no: 'Não',
          retirementForTimeService: 'Aposentadoria por tempo de serviço',
          retirementByAge: 'Aposentadoria por idade',
          specialRetirement: 'Aposentadoria especial',
          retirementDate: 'Data da Aposentadoria',
          typeRetirement: 'Tipo de Aposentadoria',
          advanceButton: 'Avançar',
          backButton: 'Voltar',
          startButton: 'Início',
          saveButton: 'Salvar',
          finishButton: 'Finalizar',
          requiredFields: 'Campos Obrigatórios',
          homeAddress: 'Endereço Residencial',
          zipCode: 'CEP',
          type: 'Tipo',
          street: 'Logradouro',
          number: 'Número',
          complement: 'Complemento',
          typeNeighborhood: 'Tipo de Bairro',
          neighborhood: 'Bairro',
          country: 'País',
          state: 'Estado',
          city: 'Cidade',
          waitMessage: 'Favor aguardar, estamos processando suas informações.',
          education: 'Escolaridade',
          includeEducation: 'Inclua a(s) sua(s) escolaridade(s)',
          course: 'Curso',
          progress: 'Andamento',
          add: 'Adicionar',
          concluded: 'Concluído',
          inProgress: 'Em progresso',
          locked: 'Trancado',
          interrupted: 'Interrompido',
          confirmDeleteMessage: 'Deseja realmente remover o registro?',
          typeCertification: 'Tipo de Certificação',
          certifyingEntity: 'Orgão Certificador',
          certificationIssueDate: 'Data da emissão da certificação',
          expirationDate: 'Data de Vencimento',
          editButton: 'Editar',
          deleteButton: 'Apagar',
          loading: 'Carregando...',
          personWithDisability: 'Pessoa com deficiência',
          disabilityWithMedicalCertificate:
            'Possui algum tipo de deficiência com laudo médico?',
          typeDisability: 'Tipo de deficiencia',
          pwd: 'PCD',
          parentingDependents: 'Dependentes / Parentabilidade',
          dependents: 'Dependentes',
          degreeKinship: 'Grau de Parentesco',
          languages: 'Idiomas',
          checkInformation: 'Confira suas informações: ',
          summary: 'Resumo',
          classOrgan: 'Órgão de Classe',
          numberClassOrgan: 'N° Registro no órgão de classe',
          registryState: 'UF de Registro',
          emissionDate: 'Data de emissão',
          haveRegistryClassOrgan: 'Possui registro em órgão de classe?',
          operator: 'Operadora',
          modality: 'Modalidade',
          quantityDay: 'Quantidade/Dia',
          totalDay: 'Total/dia',
          spouseData: 'Dados do cônjuge / Companheiro',
          dependentIncomeTax: 'Dependente Imposto de Renda',
          pension: 'Pensão',
          dependentHealthPlan: 'Dependente do plano de saúde',
          dependentDentalCare: 'Dependente de assistência odontológica',
          courseName: 'Nome do Curso',
          institution: 'Instituição',
          institutionName: 'Nome da Instituição',
          courseProgress: 'Andamento do Curso',
          startDate: 'Data de Início',
          endDate: 'Data de Término',
          start: 'Começar',
          transportationVouchers: 'Vale Transporte',
          addDocument: 'Adicionar Documento',
          remove: 'Remover',
          noDocumentRegistered: 'Nenhum documento cadastrado!',
          documents: 'Documentos',
          errorMessage: 'Ops! Algo deu errado.',
          contactResponsableMessage:
            'Favor entrar em contato com o responsável para mais informações.',
          typeVoucher: 'Tipo de vale:',
          dependentsWithBenefit: 'Dependentes com adesão ao benefício',
          dependentStage: 'Ir a etapa de dependentes',
          dataSavedMessage: 'Dados Salvos com Sucesso',
          processNotCompletedMessage:
            'Entretanto, o processo ainda não foi finalizado, você deve retornar e confirmar a finalização do processo.',
          return: 'Retornar',
          maxSizeAllowed: 'Tamanho máximo permitido',
          emptyFile: 'O arquivo está vazio',
          initialInformation: 'Informações Iniciais',
          address: 'Endereço',
          notInformed: 'Não informado',
          unfilledDocuments: 'Documentos não preenchidos',
          documentsFilled: 'Todos os documentos foram preenchidos',
          birthplace: 'Local de Nascimento',
          organ: 'Órgão',
          describeOtherCertification: 'Outro (descreva a outra certificação)',
          certificationDateOfIssue: 'Data de emissão da certificação',
          certificationExpirationDate: 'Data de validade da certificação',
          option: 'Opção',
          certifications: 'Certificações',
          haveCertification: 'Possui certificação?',
          publicAgent: 'Agente Público',
          whatRelationship: 'Qual relação',
          inss: 'INSS Recolhido em outra empresa',
          job: 'Emprego',
          workAnotherCompany: 'Trabalha em outra empresa',
          company: 'Empresa',
          role: 'Cargo',
          whatJob: 'Qual cargo exerce?',
          groupCompany: 'Qual empresa do grupo',
          familyAtCompany: 'Parente na empresa',
          family: 'Família',
          participationCompanies: 'Participação em empresas',
          companyName: 'Nome da empresa',
          foundationDate: 'Data de fundação',
          participationPercentage: 'Porcentagem de participação',
          currency: 'Moeda',
          monthlyPerformance: 'Rendimento mensal',
          supplier: 'Fornecedor',
          wichSupplierAndRelationship: 'Qual fornecedor e qual relação',
          supplierConnection: 'Ligação com fornecedor',
          origin: 'Origem',
          assetsRealState: 'Bens / Imóveis',
          originIncludedFromSpouse:
            'Origem (inclusive do cônjuge, ex: aluguéis, etc.)',
          typePropety: 'Tipo do imóvel',
          financed: 'Financiado',
          creditor: 'Credor',
          debitBalance: 'Saldo devedor',
          assetsRealStateAndOthers: 'Bens / Imóveis / Outros rendimentos',
          brand: 'Marca',
          model: 'Modelo',
          manufacturing: 'Fabricação',
          acquisitionValue: 'Valor da aquisição',
          manufactureYearModel: 'Fabricação (ano / modelo)',
          vehicles: 'Veículos',
          vehicle: 'Veículo',
          privateTransport: 'Transporte particular',
          wichVehicle: 'Qual veiculo?',
          workVehicle: 'Vai ao trabalho com veículo',
          select: 'Selecione...',
          confirmMessageParticipationCompanies:
            'Você possui participação em alguma empresa?',
          confirmMessageFamily:
            'Você possui algum familiar ou pessoa de convivência próxima que é colaborador ou terceiro relacionado ao @groupCompanyName?',
          confirmMessageSupplier:
            'Você ou algum familiar ou pessoa de convivência próxima possui ligação com algum fornecedor que atende ao @groupCompanyName?',
          confirmMessagePublicAgent:
            'Você ou algum familiar ou pessoa de convivência próxima é ou foi Agente Público ou PEP nos últimos 5 anos, ainda que licenciado, afastado ou aposentado?',
          confirmMessagePrivateTransport:
            'Usa veículo particular para trabalhar?',
          confirmMessagePaidActivity:
            'Você exerce qualquer outra atividade remunerada, ainda que sem vínculo empregatício ou de forma esporádica?',
          confirmMessageJob: 'Trabalha em outra empresa?',
          confirmMessageCertifications: 'Possui certificação?',
          advanceAndSaveButton: 'Avançar e Salvar',
          cpfOrSsn: 'CPF',
          cancel: 'Cancelar',
          unableToAddNewRecord: 'Não é possível adicionar um novo registro',
          addNewRecordToFoward: 'Adicione um registro para avançar.',
          requiredStep: 'Etapa é obrigatória, insira um ou mais registros.',
          unableToAdvance:
            'Não foi possível avançar, tente novamente mais tarde',
          leaveWithoutSaving:
            'Os campos alterados não serão gravados. Deseja sair sem gravar?',
          isRequired: 'é obrigatório.',
          institutionNameRequired: 'Nome da instituição é obrigatório.',
          courseNameRequired: 'Nome do curso é obrigatório.',
          endDateRequired: 'Data de término é obrigatório.',
          nameRequired: 'Nome é obrigatório',
          documentRequired: 'Documento é obrigatório',
          disabilityRequired: 'Tipo de deficiencia é obrigatório.',
          pleaseFillTheField: 'Por favor, preencha o campo',
          docTypeImage: 'Imagem',
          docTypeDoc: 'Documento (pdf, word)',
          docTypeImageDoc: 'Imagem / Documento (pdf, word)',
          docZip: 'zip',
          docAll: 'Todos',
          noCodeEntered: 'Nenhum código foi digitado.',
          codeValidated: 'Código validado com sucesso.',
          smsSent: 'SMS envido com sucesso.',
          completed: 'Concluido',
          inProgress: 'Em Andamento',
          locked: 'Trancado',
          interrupted: 'Interrompido',
          selectAll: 'Selecionar Todos',
          typeActivity: 'Tipo de atividade',
          frequency: 'Frequência',
          period: 'Período',
          invalidName: 'Nome inválido.',
          paidActivity: 'Atividade Remunerada',
          theField: 'O campo',
          relationship: 'Relação',
          supplierAndRelationship: 'Fornecedor e relação',
          actualLocationOfWork: 'Localidade real de trabalho',
          fullNameOfPersonWithRelationship: 'Nome completo da pessoa com vínculo',
          placeOfWork: 'Localidade de trabalho',
          WorkInDifferentCityFromWhereYouLive: 'Você trabalha em uma cidade diferente de onde mora?',
          enterTheCityWhereYouWork: 'Em caso afirmativo, insira a cidade de onde você trabalha.',
          anotherLanguage: 'Outro idioma',
          levelOfAnotherLanguage: 'Nível do outro idioma',
          english: 'Inglês',
          spanish: 'Espanhol',
          cnpjOrEin: 'CNPJ',
          invalidCnpjOrEin: 'CNPJ inválido.',
          atLeastOneParentMustBeAdded: 'É necessário adicionar pelo menos um pai ou mãe.',
          cepOrZipCodeRequired: 'CEP é obrigatório.',
          cepOrZipCodeInvalid: 'CEP inválido.',
          portuguese: 'Português',
          married: 'Casado',
          toAccessTheFormSMSMessage: 'Para acessar o formulário vamos enviar um código via SMS para o celular com final',
          theCodeIsValidForTenMinutes: 'O código tem validade de 10 minutos.',
          ifItsNearYourCellPhone: 'Caso esteja proximo do seu celular, clique no botão abaixo para realizar o envio.',
          code: 'Código',
          resend: 'Reenviar',
          sendSMS: 'Enviar SMS',
        },
      },
    },
    fallbackLng: 'en',
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
